<template>
  <div>
    <b-modal
      v-model="_state"
      size="lg"
      footer-class="border-top-0"
      centered
      no-close-on-esc
      no-close-on-backdrop
      @show="resetData"
      hide-footer
    >
      <template v-slot:modal-title>
        <h5 class="text-secondary mb-0">
          {{ $t("registration") }}
        </h5>
      </template>

      <template v-slot:default>
        <b-alert
          :show="isSubmitDisabled"
          variant="warning"
          class="mb-1 py-1 px-2"
          v-html="$t('page.parameters.noAdminWarningMsg')"
        ></b-alert>

        <base-registration-form
          userType="maintainer"
          :disableSubmit="isSubmitDisabled"
          @submit="onSubmit"
          @cancel="onCancel"
        />
      </template>
    </b-modal>
    <b-modal
      v-model="warningModal"
      size="lg"
      footer-class="border-top-0"
      centered
      no-close-on-esc
      no-close-on-backdrop
      @ok="updateMaintainer"
    >
      <template v-slot:modal-cancel>
        {{ $t("button.cancel") }}
      </template>
      <template v-slot:default>
        <p>
          {{ $t("page.maintainer.alreadyAssigned") }}
        </p>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { appMixin, authMixin } from "../../mixins";
import ConfigProvider from "../../plugins/config-provider";

import BaseRegistrationForm from "../Base/RegistrationForm";

export default {
  components: {
    BaseRegistrationForm
  },
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  mixins: [appMixin, authMixin],
  data() {
    return {
      warningModal: false,
      maintainerId: null,
      userInfo: null
    };
  },
  computed: {
    _state: {
      get() {
        return this.state;
      },
      set(value) {
        this.setModalState(value);
      }
    },
    activeAdministrator() {
      return this.$store.getters["filter/getAdministratorSelected"];
    },
    isSubmitDisabled() {
      console.log(this.activeAdministrator);
      return this.$_authUserIsDomi ? this.activeAdministrator === null : false;
    }
  },
  methods: {
    async resetData() {
      await this.$store.dispatch("parameters/retrieveCategories");
    },
    onCancel() {
      this.setModalState(false);
    },
    async onSubmit(userInfo) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        userInfo.administratorId = this.$_authUserIsDomi
          ? this.activeAdministrator._id
          : this.$_authUser._id;
        this.userInfo = userInfo;
        await this.$store.dispatch("user/createUser", {
          userInfo
        });
        await this.$store.dispatch("userMaintainer/retrieveMaintainerUsers");

        this.setModalState(false);
      } catch (error) {
        console.log(error);
        if (
          ConfigProvider.value("domiAppType") === "1BOARD" &&
          error.errorCode === 794
        ) {
          this.warningModal = true;
          this.maintainerId = error.meta.maintainerId;
        }
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    setModalState(value) {
      this.$emit("update:state", value);
    },
    async updateMaintainer() {
      try {
        await this.$store.dispatch(
          "maintainer/addAdminMaintainer",
          this.maintainerId
        );
        await this.$store.dispatch("maintainer/updateMaintainer", {
          maintainerId: this.maintainerId,
          administratorId: this.userInfo.administratorId,
          type: this.userInfo.maintainerType
        });
        this.$router.go(0);
        this.maintainerId = null;
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.alert {
  border-left: 10px solid var(--yellow);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
