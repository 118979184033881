<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.maintainerUser.title") }}</h3>

    <maintainer-user-filters class="mb-5" />

    <b-row v-if="$is1Board" class="no-gutters justify-content-end mb-3">
      <b-col class="col-auto">
        <b-button size="md" variant="secondary" @click="openRegistrationModal">
          {{ $t("button.newMaintainer") }}
        </b-button>
      </b-col>
    </b-row>

    <infinite-scroll @load="retrieveMaintainerUsers">
      <user-table
        :users="formatedUsers"
        :fields="fields"
        :actions="actions"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      />
    </infinite-scroll>
    <maintainer-details
      :modalState.sync="userDetailsModalState"
      :maintainer="selectedMaintainer"
      :isAssociateBtnVisible="false"
    />

    <registration-modal :state.sync="registrationModalState" />
  </div>
</template>

<script>
import { EventBus } from "./../eventBus.js";

import { appMixin, userMixin } from "./../mixins";

import MaintainerUserFilters from "./../components/Maintainer/MaintainerUserFilters";
import UserTable from "./../components/User/UserTable";
import MaintainerDetails from "./../components/Maintainer/MaintainerDetails";
import RegistrationModal from "../components/Maintainer/RegistrationModal";
import InfiniteScroll from "./../components/Base/BaseInfiniteScroll";
export default {
  components: {
    MaintainerUserFilters,
    UserTable,
    MaintainerDetails,
    RegistrationModal,
    InfiniteScroll
  },
  mixins: [appMixin, userMixin],
  data() {
    return {
      fields: [
        {
          key: "fullName",
          label: "nameAndSurname",
          sort: true,
          sortBy: "firstname"
        },
        { key: "businessName", label: "legalName", sort: true },
        { key: "email", label: "email", sort: true },
        { key: "createdAt", label: "createdat", sort: true },
        { key: "status", label: "status", sort: false },
        { key: "registrationDate", label: "registrationDate", sort: true },
        { key: "actions", label: "" }
      ],
      actions: [
        {
          label: "button.details",
          variant: "secondary",
          callbackFunc: this.openMaintainerDetailModal
        },
        {
          label: "button.delete",
          variant: "orange",
          hidden: user => user.status === "active",
          callbackFunc: this.suspendUser
        },
        {
          label: "page.maintainer.reactivate",
          variant: "orange",
          hidden: user => user.status === "suspended",
          callbackFunc: this.reactivateUser
        }
      ],
      registrationModalState: false,
      userDetailsModalState: false,
      selectedMaintainer: null
    };
  },
  mounted() {
    EventBus.$on("eventBusReloadMaintainerUsers", () =>
      this.retrieveMaintainerUsers(true)
    );
    this.retrieveMaintainerUsers(true);
  },
  computed: {
    maintainers() {
      return this.$store.getters["userMaintainer/getMaintainerUsers"];
    },
    formatedUsers() {
      return this.maintainers.map(item => {
        return {
          id: item._id,
          photo: this.$_getUserProfilePhoto(item.profile),
          fullName: this.$_getUserFullName(item.profile),
          businessName: item.profile.maintainerId?.name,
          email: item.email,
          status: item.status,
          createdAt: item.createdAt,
          registrationDate: item.registrationDate
        };
      });
    },
    sortBy: {
      get() {
        return this.$store.getters["userMaintainer/getSortBy"];
      },
      set(value) {
        this.$store.commit("userMaintainer/SET_SORT_BY", value);
        this.retrieveMaintainerUsers(true);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["userMaintainer/getSortDesc"];
      },
      set(value) {
        this.$store.commit("userMaintainer/SET_SORT_DESC", value);
        this.retrieveMaintainerUsers(true);
      }
    }
  },
  methods: {
    openRegistrationModal() {
      this.registrationModalState = true;
    },
    async openMaintainerDetailModal(userId) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("userMaintainer/retrieveMaintainerUser", {
          userId
        });
        this.selectedMaintainer = this.$store.getters[
          "userMaintainer/getCurrentMaintainerUser"
        ].generalInfo;
        this.userDetailsModalState = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async retrieveMaintainerUsers(initial) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("userMaintainer/retrieveMaintainerUsers", {
          hasPagination: true,
          initial
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async suspendUser(userId) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("user/suspendUser", userId);
        await this.retrieveMaintainerUsers();
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async reactivateUser(userId) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("user/reactivateUser", userId);
        await this.retrieveMaintainerUsers();
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  },
  beforeDestroy() {
    EventBus.$off("eventBusReloadMaintainerUsers");
  }
};
</script>

<style lang="scss" scoped></style>
