<template>
  <b-row class="no-gutters ml-n3">
    <b-col class="col-12 col-md-6 col-lg-4 pl-3">
      <search-input
        v-model="searchInput"
        label="search"
        :placeholder="$t('page.maintainerUser.searchPlaceholder')"
        @search="search"
      ></search-input>
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-4 pl-3">
      <form-select v-model="filterUserStatus" label="status">
        <b-form-select-option :value="null">
          {{ $t("all") }}
        </b-form-select-option>
        <b-form-select-option
          v-for="(status, index) in adminStatusOptions"
          :key="index"
          :value="status"
        >
          {{ $t(status) }}
        </b-form-select-option>
      </form-select>
    </b-col>
  </b-row>
</template>

<script>
import SearchInput from "../Base/BaseSearchInput";
import FormSelect from "../Base/BaseFormSelect";
export default {
  components: {
    SearchInput,
    FormSelect
  },
  data() {
    return {
      searchInput: "",
      adminStatusOptions: ["active", "notverified"]
    };
  },
  mounted() {
    this.searchInput = this.filterSearch;
  },
  computed: {
    filterSearch: {
      get() {
        return this.$store.getters["userMaintainer/getFilterSearch"];
      },
      set(value) {
        this.$store.commit("userMaintainer/SET_FILTER_SEARCH", value);
      }
    },
    filterUserStatus: {
      get() {
        return this.$store.getters["userMaintainer/getFilterUserStatus"];
      },
      set(value) {
        this.$store.commit("userMaintainer/SET_FILTER_USER_STATUS", value);
        this.retrieveMaintainerUsers();
      }
    }
  },
  methods: {
    search() {
      this.filterSearch = this.searchInput;
      this.retrieveMaintainerUsers();
    },
    async retrieveMaintainerUsers() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("userMaintainer/retrieveMaintainerUsers", {
          hasPagination: true,
          initial: true
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
